<template>
    <div v-if="linkPdf != false">
        <div id="heade-action" class="w-100 bg-white sticky-top p-3" style="align-items:center;justify-content:center;">
            <div>
                <img :src="$api.ste.logo" class="logo"/>
            </div>
            <div class="ml-auto">
                <b-button-group size="sm">                     
                    <b-button class="ml-1" :href="linkPdf" target="_blank">
                        <i class="fas fa-download"></i><br/>
                        <span class="h6">Telecharger</span>
                    </b-button>                    
                </b-button-group>                
            </div>
            <div class="ml-auto" v-if="user != false">
                <b-button class="ml-2" size="sm" variant="light-info" v-if="signable" @click="sign()">
                    <i class="fas" :class="!sign_load ? 'fa-signature' : 'fa-spin fa-spinner'"></i><br/>
                    <span class="h6">Signature</span>
                </b-button>
                <b-button class="ml-2" size="sm" :variant="call_color" :href="`tel:${user.telephone}`">
                    <i class="fas fa-phone"></i><br/>
                    <span class="h6">Appelez {{ user.prenom }} {{ user.nom[0] }}
                    </span>
                </b-button>
            </div>
        </div>
        <b-container v-if="ready"  class="mt-3" style="margin-bottom:50px">            
            <b-row class="justify-content-center">
                <b-col lg="9" md="11">
                    <pdf ref="myPdfComponent" v-for="i in numPages" :id="`Page-${i}`" :key="i" :src="src" :page="i" style="margin-top: 25px;"></pdf>                        
                </b-col>
            </b-row>
        </b-container>        
        <div v-else class="d-block text-center h3 text-white" style="padding-top: 20%; padding-bottom: 20%;" >
            <div>
                <strong>
                    Merci de patienter, pendant le chargement votre document
                </strong><br><br>
                <i class="fa fa-spin fa-spinner fa-4x text-danger"></i>
            </div>
        </div>        
        <call-us-selector v-if="user != false && user.pbx_chat != null" ref="pbx" phonesystem-url="https://assureur-francais.3cx.eu" :party="user.pbx_chat"></call-us-selector>
    </div>
</template>
<script>
    //import InputForm from '@/components/InputForm';
    import pdf from 'vue-pdf'    
    export default {
        name: "File",
        components: {
            pdf,
            //InputForm            
        },
        computed: {},
        data() {
            return {
                ready: false,
                call_color: 'primary',
                user:false,
                linkPdf: false,
                uuid: null,
                session: null,
                src: null,
			    numPages: undefined,
                anchor: 'Page-1',
                during:[],
                totalD:0,
                message:null, 
                sign_load: false,
                signable: false,
                sign_url: null           
            }
        },
        beforeMount(){
            this.session = this.$api.uuid();
            this.uuid = this.$route.params.uuid;
            this.$api.ajax('/file/view/'+this.uuid, null, res => {
                if(res.status == true){
                    this.user = res.data.user;
                    //this.linkPdf = res.data.link;
                    this.linkPdf = res.data.link;
                    this.signable = res.data.signable;
                    this.sign_url = res.data.sign_url;
                    this.src = pdf.createLoadingTask(this.linkPdf);                    
                    this.src.promise.then(pdf => {
                        this.numPages = pdf.numPages;
                        this.initAnchorDetector();
                        this.initDuring();
                        this.ready = true;
                    });
                }
            })
        },
        mounted() { 
            setInterval(() => {
                this.call_color = this.call_color === 'primary' ? 'light-danger' : 'primary';
            },2000)                              
        },        
        methods: {
            initAnchorDetector(){
                var the = this;
                document.addEventListener("scroll", () => {
                    for(var i = 0; i < this.numPages; i++){
                        var id = `Page-${i+1}`;
                        var elt = document.getElementById(id);
                        var top = window.pageYOffset;
                        var distance = top - elt.offsetTop;
                        if(distance < 30 && distance > -30){
                            the.anchor = id;
                        }
                    }
                });
            },
            initDuring(){
                var the = this;
                setInterval(() => {
                    var check = the.during.find(d => d.key === this.anchor);
                    if(check === undefined){
                        check = {
                            key: this.anchor,
                            duree:1
                        }
                        the.during.push(check);
                    }else{
                        check.duree += 1
                    }  
                    this.totalD += 1                 
                    if(this.totalD % 5 === 0){
                        the.sendTracking();
                    }
                }, 1000);
            },
            sendTracking(){
                var params = {
                    uuid: this.uuid,
                    session: this.session,
                    durring: this.during,
                    totalD: this.totalD
                }
                var option = {
                    headers:{
                        "Content-Type":"application/json",                        
                    }                    
                }
                option.method = "POST";
                option.body = JSON.stringify(params);
                var url = this.$api.url.api+'/file/tracking';
                return fetch(url, option);
            },
            onOpenChat(){
                var chat = document.getElementById("wplc-chat-button");
                chat.click();
                
            },
            sign(){
                if(this.sign_url === null){
                    if(this.sign_load === true){
                        return false;
                    }
                    this.sign_load = true;
                    this.$api.ajax('/file/sign/'+this.uuid, null, res => {
                        if(res.status === true){
                            this.sign_url = res.data;
                            window.open(this.sign_url, "_blank");
                        }
                        this.sign_load = false;
                    })
                }else{
                    window.open(this.sign_url, "_blank");
                }
            }
        },        
    }        
</script>
<style lang="scss">    
    .logo {
        max-width: 250px;
        
    }        
    #heade-action {
        display: flex;
    }
    @media (max-width: 768px){
        #heade-action {
            display: block;
            text-align: center;
        } 
        #heade-action div {
            margin-bottom: 10px;
        }
        #heade-action .btn {
            padding: 0.50rem 0.70rem;
            font-size: 0.925rem;
            line-height: 1.35;
            border-radius: 0.85rem;
        }                
    }            
</style>